<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pt-4>
      <v-flex xs12>
           <v-layout row justify-space-between align-center px-2>
            <v-flex xs12 sm6 md6 lg6 xl6>
              <span class="heading">Resource Videos</span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 xl6 pt-2 pt-lg-0 pt-sm-0 pt-md-0 class="text-right">
                <v-btn
                  color="#427D2D"
               
                    @click="adddialog = true"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: white;
                    "
                  >
                  Add Video
                    <v-icon color="white">mdi-plus</v-icon>
                  </span>
                </v-btn>
              </v-flex>
            
          </v-layout>
      </v-flex>
      <v-layout wrap justify-center>
        <v-flex xs12>
        

          <v-layout wrap justify-start v-if="items && items.length > 0">
            <v-flex
              xs12
              sm6
              md4 
              lg3
              v-for="(item, itemIndex) in items"
              :key="itemIndex"
              class="d-flex mb-3"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-3>
                  <v-card
                    elevation="5"
                    rounded="lg"
                    style="
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                  "
                  >
                  <iframe
                  :key="item.link"
                  :src="
                    'https://www.youtube.com/embed/' +
                    item.url +
                    '?rel=0'
                  "
                  width="100%"
                  height="200px"
                  scrolling="no"
                  contain
                  frameborder="0"
                  allowtransparency="true"
                  allowfullscreen="true"
                >
              </iframe>
                    <v-flex pa-3 pb-3>
                      <v-flex xs12 pt-1>
                        
                         <div class="d-flex justify-space-between align-center">
                          <span class="tablehead">{{ item.title }}</span>
                        </div>
                      </v-flex>
                      <template v-if="item.description">
                        <v-flex xs12 pt-1 px-4 pb-5 text-justify style="overflow: hidden;">
                          <span class="label">{{ truncateText(item.description, 40).text }}</span>
                          <v-btn icon small v-if="truncateText(item.description, 40).isTruncated" @click="openDialog(item)">
                            <v-icon color="blue">mdi-arrow-right</v-icon> <!-- Right Arrow Icon -->
                          </v-btn>
                        </v-flex>
                      </template>
                      <v-flex text-right>
                        <!-- <v-btn  small  color="success" @click="editSlider(item)">
                          <span style="color: white;">edit</span>
                       </v-btn>  -->
                       <v-icon
                       small size="20px"
                       color="primary"
                       class="ml-4"
                       @click.stop="editSlider(item)"
                     >
                       mdi-pencil
                     </v-icon>
                       &nbsp;
                        <!-- <v-btn  small   color="error"  @click="openDeleteDialog(item)">
                          <span style="color: white;">delete</span>
                       </v-btn> -->
                       <v-icon
                       small size="20px"
                       color="error"
                       class="ml-4"
                       @click.stop="openDeleteDialog(item)"
                     >
                       mdi-delete
                     </v-icon>
                      </v-flex>
                    </v-flex>
                    
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          
          

          <v-flex xs12 v-else>
            <v-layout
              wrap
              justify-center
              align-center
             
            >
            <v-flex xs12 class="text-center">
                <span class="nodata">No Data Found !</span>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 v-if="items && items.length > 0">
            <v-layout wrap justify-center>
              <v-flex xs10>
                <v-pagination
                  v-model="currentPage"
                  :length="Pagelength"
                  circle
                  color="#427D2D"
                  small
                ></v-pagination>
              </v-flex>
            </v-layout>
          </v-flex>

        </v-flex>
      </v-layout>
      
    </v-layout>




    <!-- add dialog -->
    <v-dialog v-model="adddialog" max-width="700px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="heading">ADD VIDEO</span>
          <v-spacer></v-spacer>
          <v-btn color="red" icon @click="adddialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm12 md12 lg12 class="subheading">
                <span style="color:black">Title</span>
                <v-text-field class="pt-2 text-des" outlined v-model="title" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 pt-3 class="subheading">
                <span style="color:black">Description</span>
                <v-textarea outlined v-model="description" dense hide-details class="pt-2 text-des">
                </v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12 class="subheading">
                <span style="color:black">Video URL</span>
                <v-text-field class="pt-2 text-des" outlined v-model="link" dense hide-details>
                </v-text-field>
              </v-flex>
              
           
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions class="py-4 justify-end headline  lighten-2">
          <v-btn tile outlined color="#FF1313" light :ripple="false" depressed class="itemValue" text
            @click="adddialog = false"><span style="color: black;">Cancel</span> </v-btn>
          <v-btn tile :color="appColor" light :ripple="false" depressed class="itemValue"
            @click="itemadd()"><span style="color:white;">Save Changes</span> </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog><v-dialog persistent v-model="remarkdialogedit" max-width="700px">
      <v-card rounded="lg">
        <v-card-title>
          <span class="heading">EDIT VIDEO</span>
          <v-spacer></v-spacer>
          <v-btn color="red" icon   @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-layout wrap>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left >
                <span class="subheading"> Title</span>
                <v-text-field
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.title"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-self-center pt-2 text-left >
                <span class="subheading"> Description</span>
                <v-textarea
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.description"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-flex>
              <v-flex xs12 align-self-center text-left pt-2 >
                <span class="subheading"> URL</span>
                <v-text-field
                  class="pt-2 text-des"
                  style="font-size: 14px"
                  v-model="editingitem.url"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
         
        </v-layout>
         <v-card-actions class="py-4 justify-end headline  lighten-2">
            <v-btn tile outlined color="#FF1313" light :ripple="false" depressed class="itemValue" text
            @click="closeDialog()"><span style="color: black;">Cancel</span> </v-btn>
            <v-btn tile :color="appColor" light :ripple="false" depressed class="itemValue"
            @click="edit()"><span style="color:white;">Save Changes</span> </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to delete this Video?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
             @click="confirmDelete"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Modal Dialog for Full Description -->
<v-dialog v-model="dialog" max-width="600px">
  <v-card>
    <v-card-title class="tablehead">{{ selectedItem.title }}</v-card-title>
    <v-card-text class="label" style="color: black;">{{ selectedItem.description }}</v-card-text>
    <v-card-actions>
      <v-btn color="#FF1313" text @click="dialog = false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
  </div>
</template>
<script>
import axios from "axios";


export default {
  
  data() {
    return {
      imgItem: "",
      deleteDialogImage: false,
      remarkdialogedit: false,
      dialog: false, 
      selectedItem: {},
      title: "",
      description: "",
      link:"",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      page: 1,
      currentPage: 1,
      pages: 0,
      keyword: "",
      userlist: [],
      items: [],
      status: "",
      deleteDialog: false,
      deleteid: "",
      rejectDialog: false,
      rejectid: "",
      addEventListenerdialog: false,
      adddialog: false,
      editdialog: false,
      editingitem: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage=1
    },
  },
  methods: {
    truncateText(text, length) {
      if (text && text.length > length) {
        return { text: text.substring(0, length) + '...', isTruncated: true };
      } else if (text) {
        return { text: text, isTruncated: false };
      } else {
        return { text: '', isTruncated: false }; // Return empty string if text is undefined
      }
    },
    openDialog(item) {
      // Set the selected item and open the dialog
      this.selectedItem = item;
      this.dialog = true;
    },
  
    deleteImage(imgItem) {
  this.appLoading = true;

  axios({
    method: "POST",
    url: "/resource/image/delete/single",
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
    data: {
      photo: imgItem,    // image being deleted
      id: this.imgItemId // id of the item containing the image
    },
  })
    .then((response) => {
      if (response.data.status) {
        this.appLoading = false;
        this.getData(); // Refresh data
        this.showSnackBar = true;
        this.deleteDialogImage = false;
      } else {
        this.appLoading = false;
        this.msg = response.data.msg;
        this.showSnackBar = true;
      }
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},
    editSlider(item) {
      this.editingitem = item;
      this.remarkdialogedit = true;
    },
    edit() {
      if (!this.editingitem.title) {
        this.msg = "Please Provide title.";
        this.showSnackBar = true;
        return;
      }
      if (!this.editingitem.description) {
        this.msg = "Please Provide description.";
        this.showSnackBar = true;
        return;
      }
      if (!this.editingitem.url) {
        this.msg = "Please Provide url.";
        this.showSnackBar = true;
        return;
      }
            this.appLoading = true;
            var user = {};
            user["resourceId"] = this.editingitem._id;
            user["title"] = this.editingitem.title;
            user["description"] = this.editingitem.description;
            user["url"] = this.editingitem.url;
            axios({
                url: "/resource/edit/video",
                method: "POST",
                data: user,
                headers: {
                "x-auth-token": localStorage.getItem("token"),
        },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data.status) {
                        this.remarkdialogedit = false;
                        this.getData();
                        this.msg = response.data.msg;
                        this.showSnackBar = false;
                        
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },

    closeDialog() {
      this.remarkdialogedit = false;
      this.getData();
    },
    getData() {
      this.appLoading = true;
      axios({
        method:"post",
        url:"/resource/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        // params: {
        //   //   limit: this.limit,
        //   //   page: this.currentPage,
        //   isVideoLink:true,

        // },
        data: {
          isVideoLink:true,
          count: this.count,
          page: this.currentPage,
         
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    itemadd() {

      if (!this.title) {
        this.msg = "Please Provide title.";
        this.showSnackBar = true;
        return;
      }
      if (!this.description) {
        this.msg = "Please Provide description.";
        this.showSnackBar = true;
        return;
      }
      if (!this.link) {
        this.msg = "Please Provide url.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url:"/resource/addvideolink",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          title: this.title,
          description: this.description,
          link: this.link,
         
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.adddialog = false;

            this.title = null;
            this.description = null;
            this.link = null;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },


    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deleteDialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url:"/resource/image/delete",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.deleteDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}

.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}

.zoom-container {
  position: relative;
  overflow: hidden;
}

.card-image {
  border-radius: 15px;
  height: 250px;
  /* Adjust height as needed */
  transition: transform 0.3s ease;
  /* Add transition for zoom effect */
}

.zoomed {
  transform: scale(1.2);
  /* Zoom effect when mouse hovers over the image */
}


</style>